<template>
    <div class="account-agent-token" v-if="userInfo">
        <el-card class="basic-card">
            <div slot="header" class="clearfix">智能体Token</div>
            <div class="flex flex-align-center">
                <div class="fonts-17 text-bold">Token流量：</div>
                <div class="fonts-12 margin-l-10">智能体服务不可用字符包/会员消费，需要充值Token流量才可使用</div>
            </div>
            <div class="flex flex-align-center margin-t-18">
                <div class="fonts-15">剩 <span class="color-00B670">{{userInfo.tokenQuota.surplus}}</span> token</div>
                <div class="fonts-14 margin-l-10 color-999" >1 token≈1 中文字/外文单词</div>
            </div>
            <div class="margin-t-12">
                <el-progress :percentage="userInfo.tokenQuota.usageRatio" :show-text="false" :stroke-width="5" color="#00D959" define-back-color="#E5E5E5"></el-progress>
            </div>
            <div class="token-grid" v-if="list.length > 0">
                <template v-for="(token, index) in list">
                    <div class="item" :key="index">
                        <div class="text-center">
                            <div class="fonts-40">
                                <svg-icon name="ic-token"></svg-icon>
                            </div>
                            <div class="fonts-17 text-bold margin-t-10">{{ token.desc }}</div>
                            <div class="fonts-15 text-bold margin-t-15">￥{{ token.price }}元</div>
                        </div>
                        <div class="btn" @click="showPayDialog(token)">立即购买</div>
                    </div>
                </template>
                <div class="item more">
                    <div class="text-center">
                        <div class="fonts-40">
                            <svg-icon name="ic-token"></svg-icon>
                        </div>
                        <div class="fonts-17 text-bold margin-t-10">更多Token</div>
                    </div>
                    <a href="mailto:languagex@besteasy.com" class="btn">联系我们</a>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { payApi } from '@/utils/api';
export default {
    data() {
        return {
            list: [],
        }
    },
    computed: {
		...mapState({
			userInfo: state => state.user.userData,
		}),
	},
    methods: {
        initList() {
            let url = `${payApi.queryPriceOptions}?type=TOKEN`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.list = res.data;
                }
            });
        },
        showPayDialog(token) {
            console.log(token);
            this.$store.dispatch('pay/setPayTokenDialog',{show: true, type: token.code})
        }
    },
    mounted() {
        console.log(this.userInfo);
        this.initList();
    },
}
</script>
<style lang="scss" scoped>
.account-agent-token{
    .basic-card{
        box-shadow: initial;
        ::v-deep .el-form-item__label{
            line-height: 1;
        }
    }
    .token-grid {
        margin-top: 25px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px,1fr));
        grid-gap: 10px;
        .item {
            min-height: 180px;
            background-color: #FFF5E1;
            border-radius: 10px;
            padding: 30px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .btn {
                width: 154px;
                height: 37px;
                box-sizing: border-box;
                border-radius: 50px;
                border: 1px solid #333333;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
                color: #333333;
                cursor: pointer;
            }
            &.more {
                background-color: #EFEBFB;
            }
        }
    }
}
</style>
